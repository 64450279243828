<template>
  <!-- 任职资格查询 -->
  <div class="my_viewMyRzzg_detail">
    <van-nav-bar :title="'任职资格查询'"
                 left-text=""
                 left-arrow
                 right-text="查询"
                 @click-left="onClickLeft"
                 @click-right="onClickRight">
    </van-nav-bar>
    <van-loading v-if="loadindData"
                 type="spinner"
                 color="#1989fa"
                 vertical>加载中...</van-loading>
    <div class="recruitInfo"
         v-if="!loadindData"
         :class="jump_btn_caption?'hasbtn_content':''">
      <van-collapse class="main_cols"
                    v-model="newActiveNames">
        <van-collapse-item v-for="(item, idx) in personData"
                           :key="idx"
                           :title="item.title"
                           :name="idx">
          <van-collapse class="son_cols"
                        v-model="item.itemActiveNames">
            <van-collapse-item v-for="(it, key) in item.content"
                               :key="key"
                               :title="item.content.length == 1 ? item.title : item.title + (key + 1)"
                               :name="key">
              <van-row v-for="(el, index) in it"
                       :key="index">
                <van-col span="8">
                  <span>{{ el.key }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ el.value }}</span>
                </van-col>
              </van-row>
            </van-collapse-item>
          </van-collapse>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="btn_box"
         v-if="jump_btn_caption">
      <van-button @click="jumpToWeb"
                  type="info">{{jump_btn_caption}}</van-button>
    </div>

    <!-- 查询弹窗 -->
    <van-popup v-model="showQueryPop"
               position="right"
               :style="{ height: '100%', width: '100%' }"
               class="editSC_main_pop">
      <van-nav-bar title="查询"
                   left-arrow
                   @click-left="showQueryPop = false"
                   :fixed="true" />
      <div class="content">
        <div class="ydnrBox">
          <div v-for="(list, idx) in fastqueryinfo"
               :key="idx"
               class="ydnrList">
            <!-- 文本框 -->
            <van-field placeholder="请输入"
                       v-if=" 
                list.controltype == 'edit' &&
                !list.ispoptree &&
                list.poptreename != 'emptree'
              "
                       v-model="list.fieldvalue"
                       :label="list.chnname" />
            <!-- 普通下拉框 测-->
            <van-field placeholder="请输入"
                       v-else-if=" 
                list.controltype === 'combobox' &&
                !list.ispoptree &&
                list.listsql != '@selectmonth' &&
                list.listsql != '@selectyear'
              "
                       :clickable="list.comdropstyle == 'lsEditList' ? false : true"
                       :readonly="list.comdropstyle == 'lsEditList' ? false : true"
                       v-model="list.fieldvalue"
                       :label="list.chnname"
                       @click="
                comboboxNoispoptreefocus(
                  list,
                  list.listsql,
                  list.controltype,
                  1
                )
              ">
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon @click.stop="
                    comboboxNoispoptreefocus(
                      list,
                      list.listsql,
                      list.controltype,
                      2
                    )
                  "
                          name="arrow-down" />
              </template>
            </van-field>
            <!-- 表格下拉框  测-->
            <van-field placeholder="请输入"
                       v-else-if=" 
                list.controltype === 'lookupcombobox'
              "
                       clickable
                       readonly
                       v-model="list.fieldvalue"
                       :label="list.chnname"
                       @click="
                comboboxNoispoptreefocus(
                  list,
                  list.listsql,
                  list.controltype
                )
              ">
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 下拉框弹出树(非员工树) 测-->
            <van-field placeholder="请输入"
                       v-else-if=" 
                list.controltype === 'combobox' &&
                list.ispoptree &&
                list.poptreename != 'emptree' &&
                list.listsql != '@selectmonth' &&
                list.listsql != '@selectyear'
              "
                       clickable
                       readonly
                       v-model="list.fieldvalue"
                       :label="list.chnname"
                       @click="showTree(list)">
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 下拉框弹出树(员工树) 测 -->
            <van-field placeholder="请输入"
                       v-else-if=" 
                (list.controltype == 'combobox' || list.controltype == 'edit') &&
                list.ispoptree &&
                list.poptreename === 'emptree' &&
                list.listsql != '@selectmonth' &&
                list.listsql != '@selectyear'
              "
                       v-model="list.fieldvalue"
                       :label="list.chnname">
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down"
                          @click="showSelectJobNum(list)" />
              </template>
            </van-field>
            <!-- 年份 测 -->
            <van-field placeholder="请输入"
                       v-else-if=" 
                list.controltype === 'combobox' &&
                list.listsql == '@selectyear'
              "
                       clickable
                       readonly
                       v-model="list.fieldvalue"
                       :label="list.chnname"
                       @click="settingYear(list)">
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 月份 测 -->
            <van-field placeholder="请输入"
                       v-else-if=" 
                list.controltype === 'combobox' &&
                list.listsql == '@selectmonth'
              "
                       clickable
                       readonly
                       v-model="list.fieldvalue"
                       :label="list.chnname"
                       @click="settingYearMonth(list)">
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 日期框 测 -->
            <van-field placeholder="请输入"
                       v-else-if=" 
                list.controltype === 'datetime'
              "
                       clickable
                       readonly
                       v-model="list.fieldvalue"
                       :label="list.chnname"
                       @click="settingDatetime(list)">
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
          </div>
        </div>
        <div class="footerBox">
          <div class="flexBox">
            <van-button class="btn1"
                        color="#999999"
                        @click="showQueryPop = false">返回</van-button>
            <van-button @click="confirmQuery"
                        type="info"
                        class="btn2">确定</van-button>

          </div>
        </div>
      </div>
    </van-popup>

    <!-- combobox控件 -->
    <van-popup v-model="showComboboxPicker"
               position="bottom">
      <van-picker :title="selectFormdata.chnname"
                  show-toolbar
                  value-key="dicvalue"
                  :columns="comboboxChoices"
                  @cancel="showComboboxPicker = false"
                  @confirm="onComboboxConfirm"
                  :default-index="comboboxDefaultIndex" />
    </van-popup>
    <!-- lookupCombobox控件 -->
    <van-popup v-model="showLookupComboboxPicker"
               position="bottom">
      <div style="width: 100%;overflow: hidden;padding: 10px;">
        <div class="table_box"
             style="overflow: auto;background: #fff;">
          <table ref="table"
                 class="table">
            <tr class="top"
                v-for="(choice, i) in lookupcomboboxChoices.head"
                :key="i + '1'">
              <th v-for="(key, j) in choice.name"
                  :key="j">
                <div style="margin: 0 auto;"
                     class="title"
                     :style="choice.width[j] ? { width: choice.width[j] * 10 + 'px' } : 'min-width:90px'"
                     :c="choice.width[j]">
                  {{ key }}
                </div>
              </th>
            </tr>
            <tr @click="onLookupComboboxConfirm(choice, i)"
                class="cont"
                v-for="(choice, i) in lookupcomboboxChoices.data"
                :key="i"
                :class="choice.indexOf('isLookupcomboboxActive') != -1 ? 'isLookupcomboboxActiveBg' : ''">
              <td v-for="(k, idx) in choice"
                  :key="idx"
                  v-show="k != 'isLookupcomboboxActive'">
                {{ k }}
              </td>
            </tr>
          </table>
        </div>
        <div class="bottomPage"
             style="margin-top: 3px;">
          <van-pagination class="empPage"
                          v-model="currentPage"
                          :total-items="lookupcomboboxTotal"
                          :show-page-size="3"
                          force-ellipses
                          @change="lookupcomboboxPageChange" />
        </div>
      </div>
    </van-popup>

    <!-- 选择人员树弹窗 -->
    <PopTree ref="popTreeNode"
             :whichTree="whichTreeName"
             module="1504"
             :multiseltree="isMultiselTree"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
    <!-- dateTime控件 -->
    <van-popup v-model="showDatetimePicker"
               position="bottom">
      <van-datetime-picker :title="selectFormdata.chnname"
                           v-model="currentDatetime"
                           type="date"
                           @confirm="onDatetimeConfirm"
                           @cancel="showDatetimePicker = false" />
    </van-popup>

  </div>
</template>

<script>
import { Toast, Dialog, Notify } from 'vant';
import PopTree from '@components/PopTree/index.vue'
import {
  getEmpStdEmployeMentInfo,
  getInfo as getbaseforminfo,
  GetFilterDropDownList,
  getSqlValue,
  // GetFormFieldDetail,
  // GetFormRecordData,
  // queryGridData,
} from "@api/wx.js";
import { parseTime, paging } from '@/utils'
export default {
  components: {
    PopTree,
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
    return {
      userInfo,
      loadindData: false,
      fastquerylist: [],
      personData: [],
      newActiveNames: [],
      jump_btn_caption: '', // 按钮名称
      jump_btn_url: '', // 按钮跳转地址
      showQueryPop: false,
      fastqueryinfo: [],
      mid: 1504,
      selectFormdata: {}, // 选中的字段（用于接收弹窗返回信息）
      showComboboxPicker: false,
      comboboxChoices: [],
      showLookupComboboxPicker: false,
      lookupcomboboxChoices: {
        head: [],
        data: []
      },
      lookupcomboboxTotal: 0, // 进度条 总数
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页多少条数据
      searchVal: "", // lookupcombobox模糊搜索值
      lookupcomboboxList: [],
      bigArray: [],
      comboboxDefaultIndex: 0,  // 选择控件的默认值
      isMultiselTree: false,
      whichTreeName: "",
      currentDatetime: new Date(),
      showDatetimePicker: false,  // 日期
    }
  },
  created () {
    this.getEmpStdEmployeMentInfoData()
  },
  methods: {
    jumpToWeb () {
      if (this.jump_btn_url)
      {
        window.open(this.jump_btn_url, '_blank')
      }
    },
    // 查询确定按钮
    confirmQuery () {
      this.fastquerylist = []
      // let isnullP = false
      this.fastqueryinfo.forEach(e => {
        let value1 = ''
        let value2 = ''
        // if (
        //   e.controltype === 'combobox' &&
        //   !e.ispoptree &&
        //   !((e.fieldname == 'yymm' || e.fieldname == 'yyyy') && e.isrange)
        // )
        // {
        //   // value1 = e.enval
        //   if (e.check)
        //   {
        //     value1 = e.enval
        //     this.enval = value1
        //     if (e.isrange)
        //     {
        //       value2 = e.enval1
        //     }
        //   } else
        //   {
        //     value1 = ''
        //     value2 = ''
        //   }
        // } else if (
        //   e.controltype == 'combobox' &&
        //   (e.fieldname == 'yymm' || e.fieldname == 'yyyy') &&
        //   e.isrange
        // )
        // {
        //   if (e.check)
        //   {
        //     value1 = e.monthRange[0]
        //     value2 = e.monthRange[1]
        //   } else
        //   {
        //     value1 = ''
        //     value2 = ''
        //   }
        // } else if (
        //   e.controltype === 'combobox' &&
        //   e.ispoptree &&
        //   e.poptreename !== 'emptree'
        // )
        // {
        //   value1 = e.treevalue
        // } else if (e.controltype === 'edit')
        // {
        //   // value1 = e.nameval
        //   if (e.check)
        //   {
        //     value1 = e.nameval
        //     if (e.isrange)
        //     {
        //       value2 = e.nameval1
        //     }
        //   } else
        //   {
        //     value1 = ''
        //     value2 = ''
        //   }
        // } else if (e.controltype === 'datetime')
        // {
        //   if (e.check)
        //   {
        //     let isnull = ''
        //     if (e.isrange)
        //     {
        //       value1 = e.dateRange[0]
        //       value2 = e.dateRange[1]
        //     } else
        //     {
        //       value1 = e.Entry
        //     }
        //     if (value1 === '' || value1 === null)
        //     {
        //       isnull = this.$t('Toolbar.msg1', [e.chnname])
        //     }
        //     if (e.isrange && isnull == '')
        //     {
        //       // value2 = e.Entry1
        //       if (value2 === '' || value2 === null)
        //       {
        //         isnull = this.$t('Toolbar.msg2', [e.chnname])
        //       }
        //     }
        //     if (isnull != '')
        //     {
        //       this.$message.warning(isnull)
        //       // isnullP = true
        //       return
        //     }
        //   } else
        //   {
        //     value1 = ''
        //     value2 = ''
        //   }
        // } else if (
        //   e.controltype === 'combobox' &&
        //   e.ispoptree &&
        //   e.poptreename === 'emptree'
        // )
        // {
        //   value1 = e.noValue
        // } else if (e.controltype === 'lookupcombobox')
        // {
        //   // value1 = e.noValue
        //   if (e.check)
        //   {
        //     value1 = e.enval
        //     if (e.isrange)
        //     {
        //       value2 = e.enval1
        //     }
        //   } else
        //   {
        //     value1 = ''
        //     value2 = ''
        //   }
        // }
        const tj = {
          fieldname: e.fieldname,
          controltype: e.controltype,
          isrange: e.isrange,
          isconvert: e.isconvert,
          convertsql: e.convertsql,
          ispart: e.ispart,
          fieldvalue1: e.fieldvalue,
          fieldvalue2: e.fieldvalue
        }
        this.fastquerylist.push(tj)
      })
      // console.log(this.fastquerylist, 'this.fastquerylist');
      this.showQueryPop = false
      this.getEmpStdEmployeMentInfoData()
    },
    // 查看简历数据
    getEmpStdEmployeMentInfoData () {
      this.loadindData = true
      getEmpStdEmployeMentInfo({
        empcode: this.userInfo.empcode,
        querylist: this.fastquerylist,
        username: this.userInfo.username
      }).then(res => {
        let list = res.data;
        this.newActiveNames = []
        list.forEach((item, idx) => {
          item.name = idx;
          this.newActiveNames.push(idx)
          item.itemActiveNames = []
          item.content.forEach((el, idd) => {
            item.itemActiveNames.push(idd)
          });
        })
        this.personData = list;
        // this.baseInfo = res.baseinfo;
        this.jump_btn_caption = res.jump_btn_caption
        this.jump_btn_url = res.jump_btn_url
        console.log(this.personData, ' this.personData');
      }).finally(() => {
        this.loadindData = false
      })
    },
    // 右上角查询
    onClickRight () {
      this.showQueryPop = true
      this.getbaseforminfoData()
    },
    // 返回
    onClickLeft () {
      console.log(this.$route.query.from, 'this.$route.query.from');
      if (this.$route.query.from == 'home')
      {
        this.$router.push('/home')
      } else
      {
        this.$router.push('/personCen')
      }
    },
    // 获取查询条件
    getbaseforminfoData () {
      getbaseforminfo({
        moduleno: 1504,
        userid: this.userInfo.userid,
        langid: localStorage.getItem('langid'),
        flag: 0,
        mtag: 3
      }).then(async (res) => {
        // this.moduleConfig = res.moduleconfiginfo[0]
        const list = res.fastqueryinfo
        // console.log(n, 'listlist')
        const groups = []
        const idxs = [0]
        // 循环查询条件
        // list.forEach((item, idx) => {
        for (let idx = 0; idx < list.length; idx++)
        {
          let item = list[idx]
          const sql = await this.SqlValue(item.defvalue)
          var defvalue = item.defvalue ? sql : ''
          item.fieldvalue = defvalue
          item.showTree = false // 不展示下拉框的树结构
          item.Entry = parseTime(new Date(), '{y}/{m}/{d}') // 开始时间
          item.Entry1 = parseTime(new Date(), '{y}/{m}/{d}') // 结束时间（不启用范围是空值）
          // 联动日期
          item.dateRange = [
            parseTime(new Date(), '{y}/{m}/{d}'), // 开始时间
            parseTime(new Date(), '{y}/{m}/{d}') // 结束时间（不启用范围是空值）
          ]
          item.monthRange = item.fieldname == 'yyyy' ? [
            parseTime(new Date(), '{y}'), // 开始年份
            parseTime(new Date(), '{y}') // 结束年份（不启用范围是空值）
          ]
            : [
              parseTime(new Date(), '{y}{m}'), // 开始月份
              parseTime(new Date(), '{y}{m}') // 结束月份（不启用范围是空值）
            ] // 联动月份
          item.nameval = defvalue // 文本框
          item.nameval1 = defvalue // 文本框
          item.treevalue = defvalue // 下拉树
          item.enval = defvalue // 普通下拉框
          item.enval1 = defvalue // 普通下拉框
          item.check =
            !item.issel ||
              (item.defvalue &&
                item.defvalue.toString() != '0' &&
                item.defvalue.toString().toLowerCase != 'false')
              ? true
              : false // 复选框
          item.noValue = defvalue // 员工树
          if (item.breakline && idx !== 0) idxs.push(idx)
          if (
            (item.controltype == 'datetime' && item.listsql) ||
            (item.controltype == 'combobox' &&
              item.isrange &&
              item.listsql &&
              (item.fieldname == 'yymm' || item.fieldname == 'yyyy'))
          )
          {
            // 日期框设置了默认值
            await this.getlist(item)
          }
        }
        this.fastqueryinfo = list
        // console.log(this.fastqueryinfo, 'getInfo');
      })
    },
    // 下拉框默认值
    SqlValue (b4sql) {
      return new Promise(async (resolve, reject) => {
        let sql = b4sql === null ? '' : b4sql.replace(/(^\s*)|(\s*$)/g, '')
        if (sql.length > 7)
        {
          if (sql.substring(0, 7).toLowerCase() === 'select ')
          {
            const params = {
              sql: b4sql,
              username: this.userInfo.username
            }
            const res = await getSqlValue(params)
            sql = res.topvalue
            resolve(sql)
          }
        } else
        {
          // console.log(sql, 'sql')
          resolve(sql)
        }
      })
    },
    // 嵌入查询
    getlist (data) {
      const username = this.userInfo.username;
      // this.oid.showTree = false;
      this.$forceUpdate();
      const params = {
        moduleno: this.mid,
        username: username,
        listsql: data.listsql,
      };
      GetFilterDropDownList(params).then((res) => {
        if (data.controltype == "combobox")
        {
          // 普通下拉框只取第一个属性值，并且明明为dicvalue
          res.data.forEach((item) => {
            item.dicvalue = item[Object.keys(item)[0]];
          });
          this.job = res.data;
        } else if (data.controltype == "lookupcombobox")
        {
          this.pagesize = 10;
          let tempdata2 = { head: [{ name: [], width: [] }], data: [] },
            li = [];
          if (res.data.length > 0)
          {
            Object.keys(res.data[0]).forEach((item) => {
              tempdata2.head[0].name.push(item);
              tempdata2.head[0].width.push(item.toString().length);
            });
          }
          //表格下拉框需要新增表头数据
          for (let i = 0; i < res.data.length; i++)
          {
            var tp = [];
            for (let j = 0; j < tempdata2.head[0].name.length; j++)
            {
              let v = res.data[i][tempdata2.head[0].name[j]];
              v =
                v === null
                  ? ""
                  : v.toString().toLowerCase() === "true"
                    ? this.$t("module.yes")
                    : v.toString().toLowerCase() === "false"
                      ? this.$t("module.no")
                      : v;
              tp.push(v);
              if (v != null)
              {
                let vl = v.toString().length;
                tempdata2.head[0].width[j] =
                  tempdata2.head[0].width[j] >= vl
                    ? tempdata2.head[0].width[j]
                    : vl;
              }
            }
            li.push(tp);
            // tempdata2.data.push(tp)
          }
          for (let j = 0; j < tempdata2.head[0].name.length; j++)
          {
            let name = tempdata2.head[0].name[j];
            tempdata2.head[0].width[j] =
              name.indexOf("_") > -1
                ? parseInt(name.split("_")[1]) * 2 + "px"
                : tempdata2.head[0].width[j] + "em";
            tempdata2.head[0].name[j] =
              name.indexOf("_") > -1 ? name.split("_")[0] : name;
          }
          this.list = li;
          this.total = this.list.length;
          this.bigArray = paging(this.pagesize, this.list);
          this.currentPage = 1;
          tempdata2.data = this.bigArray[this.currentPage - 1];
          this.choices = tempdata2;
        }
      });
    },
    // select相关
    comboboxNoispoptreefocus (list, data, controltype, type) {
      if (type == 1 && list.comdropstyle == 'lsEditList')
      {
        return;
      }
      const selectListFormData = list.fieldvalue;
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '请求中...',
      });
      // this.updateformstr();
      this.selectFormdata = list;
      const params = {
        moduleno: this.mid,
        username: this.userInfo.username,
        listsql: list.listsql,
      };
      GetFilterDropDownList(params).then((res) => {
        Toast.clear();
        if (controltype === "combobox")
        {
          this.comboboxDefaultIndex = 0;
          this.showComboboxPicker = true;
          let tempdata = [];
          //普通下拉框只取第一个属性值，并且明明为dicvalue
          res.data.forEach((item) => {
            tempdata.push({ dicvalue: item[Object.keys(item)[0]] });
          });
          this.comboboxChoices = tempdata;
          if (selectListFormData)
          {
            for (let i = 0; i < tempdata.length; i++)
            {
              if (tempdata[i].dicvalue == selectListFormData)
              {
                this.comboboxDefaultIndex = i;
                break;
              }
            }
          }
        } else if (controltype === "lookupcombobox")
        {
          this.lookupcomboboxList = []
          let tempdata2 = { head: [{ name: [], width: [] }], data: [] },
            li = [];
          if (res.data.length > 0)
          {
            Object.keys(res.data[0]).forEach((item) => {
              tempdata2.head[0].name.push(item);
              tempdata2.head[0].width.push(item.toString().length);
            });
          }
          //表格下拉框需要新增表头数据
          for (let i = 0; i < res.data.length; i++)
          {
            var tp = [];
            for (let j = 0; j < tempdata2.head[0].name.length; j++)
            {
              let v = res.data[i][tempdata2.head[0].name[j]];
              v =
                v === null
                  ? ""
                  : v.toString().toLowerCase() === "true"
                    ? '是'
                    : v.toString().toLowerCase() === "false"
                      ? '否'
                      : v;
              tp.push(v);
              if (v != null)
              {
                let vl = v.toString().length;
                tempdata2.head[0].width[j] =
                  tempdata2.head[0].width[j] >= vl ? tempdata2.head[0].width[j] : vl;
              }
            }
            li.push(tp);
          }
          for (let j = 0; j < tempdata2.head[0].name.length; j++)
          {
            let name = tempdata2.head[0].name[j];
            tempdata2.head[0].name[j] = name.indexOf("_") > -1 ? name.split("_")[0] : name;
          }
          this.lookupcomboboxList = li;
          let isLookupcomboboxActiveIndex = 0;
          this.currentPage = 1;
          if (selectListFormData)
          {
            for (let i = 0; i < this.lookupcomboboxList.length; i++)
            {
              if (selectListFormData == this.lookupcomboboxList[i][0])
              {
                this.lookupcomboboxList[i].push("isLookupcomboboxActive")
                isLookupcomboboxActiveIndex = i;
              }
            }
            this.currentPage = Math.ceil((isLookupcomboboxActiveIndex + 1) / this.pageSize)
          }
          this.lookupcomboboxTotal = this.lookupcomboboxList.length;
          this.bigArray = paging(this.pageSize, this.lookupcomboboxList);
          tempdata2.data = this.bigArray[this.currentPage - 1];
          this.lookupcomboboxChoices = tempdata2;
          this.showLookupComboboxPicker = true;
        }
      })
    },
    // combobox控件
    onComboboxConfirm (obj) {
      this.setValue(this.selectFormdata.fieldname, obj.dicvalue)
      this.showComboboxPicker = false;
    },
    // lookupcombobox控件
    onLookupComboboxConfirm (obj, index) {
      this.setValue(this.selectFormdata.fieldname, obj[0])
      this.showLookupComboboxPicker = false;
    },
    lookupcomboboxPageChange () {
      this.lookupcomboboxChoices.data = this.bigArray[this.currentPage - 1];
    },
    // 设置字段值（不触发数据带出）
    setValue (n, v, tar = "new") {
      this.fastqueryinfo.forEach((i) => {
        if (i.fieldname === n)
        {
          let vv = v;
          if (i.fielddatatype === "bit")
          {
            const truearr = [1, "1", "true", "True", "TRUE", true];
            vv = truearr.indexOf(v) != -1 ? true : false;
          } else if (
            (i.controltype === "longdatetime" || i.controltype === "datetime") && vv !== null
          )
          {
            if (i.controltype === "datetime")
            {
              vv = parseTime(vv, "{y}-{m}-{d}")
            }
            if (i.datetime === "longdatetime")
            {
              vv = parseTime(vv, '{y}-{m}-{d} {h}:{i}:{s}')
            }
          } else if (i.controltype === "colorcombobox" && vv !== null)
          {
            vv = "#" + int2hex(vv);
          } else if (i.controltype === "memo" && vv !== null)
          {
            vv = vv.toString().replace(/\\n/g, "\n").replace(/\\r/g, "\r");
          } else if (i.controltype === "combobox" && vv !== null)
          {
            if (i.listsql == '@selectyear')
            {
              // vv = parseTime(vv, "{y}")
            } else if (i.listsql == '@selectmonth')
            {
              // vv = parseTime(vv, "{y}-{m}")
            }
          }
          i.fieldvalue = vv;
          if (tar === "old")
          {
            i.oldvalue = vv;
            i.haschange = false;
            i.newvalue = "";
          } else if (tar === "new")
          {
            i.haschange = true;
            i.newvalue = vv;
          }
        }
      });
      this.$forceUpdate();
    },
    // 非员工树
    showTree (list) {
      this.whichTreeName = ''
      this.selectFormdata = list;
      this.isMultiselTree = list.multiseltree ? list.multiseltree : false
      this.$refs.popTreeNode.module = '1504'
      this.$refs.popTreeNode.treename = list.poptreename
      this.$refs.popTreeNode.fieldname = this.selectFormdata.fieldname
      this.$refs.popTreeNode.editstate = 2
      this.$refs.popTreeNode.showEmpTreePop('数据')
    },
    // 确定树的选择
    treeSelectValEvent (data) {
      // this.$emit("setCanSave", true);
      // this.hasEXfieldlist = [];
      if (this.selectFormdata.multiseltree)
      {
        //多选树
        const namelist = data.map((it) =>
          it.keyname.indexOf(" ") != -1 ? it.keyname.split(" ")[0] : it.keyname
        );
        const names = namelist.join(",");
        this.setValue(this.selectFormdata.fieldname, String(names));
      } else
      {
        //单选树
        this.setValue(
          this.selectFormdata.fieldname,
          data.keyname.indexOf(" ") != -1 ? data.keyname.split(" ")[0] : data.keyname
        );
        this.setValue(
          this.selectFormdata.popupdatefield,
          data[this.selectFormdata.popbackkeyid]
        );
      }
    },
    showSelectJobNum (list) {
      this.whichTreeName = 'employee'
      this.selectFormdata = list;
      this.isMultiselTree = list.multiseltree ? list.multiseltree : false
      this.$refs.popTreeNode.module = '1504'
      this.$refs.popTreeNode.treename = list.poptreename
      this.$refs.popTreeNode.fieldname = this.selectFormdata.fieldname
      this.$refs.popTreeNode.editstate = 2
      this.$refs.popTreeNode.showEmpTreePop('数据')
    },
    // Datetime控件
    settingDatetime (list) {
      this.selectFormdata = list
      this.currentDatetime = list.formdata ? new Date(list.formdata) : new Date()
      this.showDatetimePicker = true;
    },
    // Datetime控件
    onDatetimeConfirm (time) {
      const v = parseTime(time, '{y}-{m}-{d}')
      this.setValue(this.selectFormdata.fieldname, v)
      this.showDatetimePicker = false
    },

  }

}
</script>

<style lang="less" scoped>
.editSC_main_pop {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 96px);
    margin-top: 96px;
    .ydnrBox {
      height: calc(100% - 100px);
      overflow-y: auto;
    }
    .footerBox {
      padding: 10px 0;
      height: 100px;
      background: #fff;
      display: flex;
      align-items: center;
      .flexBox {
        width: 100vw;
        flex-wrap: nowrap;
        overflow: hidden;
        height: 80%;
        display: flex;
        justify-content: space-around;
        .btn1 {
          width: 35%;
          height: 100%;
        }
        .btn2 {
          width: 55%;
          height: 100%;
        }
      }
    }
  }
}

.my_viewMyRzzg_detail {
  width: 100%;
  height: 100%;
  position: relative;
  .van-nav-bar {
    background: #2b8df0;
    z-index: 99;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .van-loading {
    margin-top: 100%;
    height: calc(100% - 212px);
  }
  .recruitInfo {
    height: calc(100% - 95px);
    // margin-top: 96px;
    overflow-y: auto;
    .sontitle {
      margin-top: 0;
      margin-bottom: 20px;
      padding: 20px 0;
      text-align: center;
      font-size: 32px;
      font-family: Source Han Sans CN;
      color: #000;
      background: #eef7ff;
    }
    .van-row {
      margin-bottom: 30px;
      .van-col:first-child {
        font-size: 28px;
        font-family: Source Han Sans CN;
        color: #999;
      }
      .van-col:last-child {
        font-size: 28px;
        font-family: Source Han Sans CN;
        color: #333;
      }
    }
    .main_cols {
      /deep/.van-collapse-item {
        .van-cell {
          margin-top: 30px;
          border-radius: 20px;
          .van-cell__title {
            span {
              font-size: 36px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #000000;
              opacity: 1;
            }
          }
        }
      }
      .van-collapse-item--border::after {
        border: unset;
      }
    }
    .son_cols {
      /deep/.van-collapse-item {
        .van-cell {
          background: #eef7ff;
          margin-top: 0;
          border-radius: unset;
          .van-cell__title {
            text-align: center;
            span {
              font-size: 32px;
              font-family: Source Han Sans CN;
              color: #000;
            }
          }
        }
      }
      .van-collapse-item:not(:first-child) {
        margin-top: 20px;
      }
      .van-collapse-item--border::after {
        border: unset;
      }
    }
    .van-hairline--top-bottom::after {
      border: unset;
    }
  }
  .hasbtn_content {
    height: calc(100% - 95px - 100px);
  }
  .btn_box {
    padding: 10px 0;
    height: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .van-button {
      height: 80%;
      width: 80%;
      font-size: 28px;
      padding: 10px 0;
      border-radius: 20px;
    }
  }
}
</style>